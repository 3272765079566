import { FC, memo } from 'react';
import { ImageSize } from '../../ownership/ProfileImageStack';
import ProfileAvatar from '../../shared/profile-image/ProfileAvatar';

type Prop = {
  id: string;
  firstName: string;
  lastName: string;
  userImageId: string;
};

const UserRenderer: FC<{ data: Prop | Prop[] }> = (props) => {
  if (!props.data) return '-';

  const data = Array.isArray(props.data) ? props.data : [props.data];
  if (!data?.length) return '-';

  return <ProfileAvatar user={data[0]} size={ImageSize.XS} />;
};

export default memo(UserRenderer);
